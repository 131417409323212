<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        :model="deliveryForm"
        :rules="deliveryRules"
        v-loading="dialogLoading"
        ref="refDeliveryForm"
        label-width="130px"
      >
        <el-form-item label="城市仓" prop="logistics_id">
          <div>
            <el-input
              :disabled="true"
              v-model="cityStoreName"
              placeholder="请输入城市仓"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="自提点名称" prop="name">
          <div>
            <el-input
              v-model="deliveryForm.name"
              placeholder="请输入自提点名称"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="自提点地址" prop="address">
          <div>
            <el-input
              v-model="deliveryForm.address"
              placeholder="请输入自提点地址"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="账号昵称" prop="nickname">
          <div>
            <el-input
              v-model="deliveryForm.nickname"
              placeholder="请输入账号昵称"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="登录账号" prop="account_number">
          <div>
            <el-input
              autocomplete="off"
              :disabled="isEdit"
              v-model="deliveryForm.account_number"
              placeholder="请输入登录账号"
              clearable
              ><el-button
                type="text"
                slot="suffix"
                :disabled="!businessUserId"
                @click="onHandleResetPassword"
                >重置密码</el-button
              ></el-input
            >
          </div>
        </el-form-item>
        <!-- 新增展示 start -->
        <el-form-item label="密码" prop="password" v-if="!isEdit">
          <div>
            <el-input
              type="password"
              v-model="deliveryForm.password"
              placeholder="请输入密码"
              clearable
              show-password
            ></el-input>
          </div>
        </el-form-item>
        <!-- 新增展示 end -->
        <el-form-item label="联系电话" prop="mobile">
          <div>
            <el-input
              v-model="deliveryForm.mobile"
              placeholder="请输入联系电话"
              maxlength="11"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="运费标准" prop="freight_parameter">
          <div>
            <el-input
              v-model="deliveryForm.freight_parameter"
              @input="
                deliveryForm.freight_parameter = tool.changeValue(
                  deliveryForm.freight_parameter
                )
              "
              placeholder="请输入运费标准"
              clearable
              ><span slot="suffix" style="color: #333333">元</span></el-input
            >
          </div>
        </el-form-item>
        <el-form-item label="运费分成" prop="freight_share">
          <div>
            <el-input
              v-model="deliveryForm.freight_share"
              @input="
                deliveryForm.freight_share = tool.changeValue(
                  deliveryForm.freight_share
                )
              "
              placeholder="请输入运费分成"
              clearable
              ><span slot="suffix" style="color: #333333">%</span></el-input
            >
          </div>
        </el-form-item>
        <!-- 服务费标准 start -->
        <el-form-item label="服务费标准" prop="foundation_prop">
          <div>
            <div class="d-flex a-center">
              <span style="white-space: nowrap">商品单价基点：</span>
              <el-input
                v-model="deliveryForm.purchase_parameter.foundation"
                placeholder="请输入商品单价基点"
                clearable
                ><span slot="suffix" style="color: #333333">元</span></el-input
              >
            </div>
            <div class="d-flex a-center mt-10">
              <el-select
                @change="onChangeSymbol('foundationGreater')"
                v-model="foundationGreater.symbol"
                clearable
                placeholder="选择条件"
              >
                <el-option label="大于等于" value=">="></el-option>
                <el-option label="大于" value=">"></el-option>
              </el-select>
              <div class="base-margin">基点</div>
              <el-input
                v-model="foundationGreater.amount"
                placeholder="请输入金额"
                clearable
                ><span slot="suffix" style="color: #333333"
                  >元/件</span
                ></el-input
              >
            </div>
            <div class="d-flex a-center mt-10">
              <el-select
                @change="onChangeSymbol('foundationLess')"
                v-model="foundationLess.symbol"
                clearable
                placeholder="选择条件"
              >
                <el-option label="小于等于" value="<="></el-option>
                <el-option label="小于" value="<"></el-option>
              </el-select>
              <div class="base-margin">基点</div>
              <el-input
                v-model="foundationLess.amount"
                placeholder="请输入金额"
                clearable
                ><span slot="suffix" style="color: #333333"
                  >元/件</span
                ></el-input
              >
            </div>
          </div>
        </el-form-item>
        <!-- 服务费标准 end -->
        <el-form-item label="服务费分成" prop="purchase_share">
          <div>
            <el-input
              v-model="deliveryForm.purchase_share"
              @input="
                deliveryForm.purchase_share = tool.changeValue(
                  deliveryForm.purchase_share
                )
              "
              placeholder="请输入服务费分成"
              clearable
              ><span slot="suffix" style="color: #333333">%</span></el-input
            >
          </div>
        </el-form-item>

        <!-- 未升仓的表单项 start -->
        <template v-if="upgradePosition === UPGRADE_POSITION_ENUM.common.value">
          <el-form-item label="提现银行卡" prop="card_code">
            <div>
              <el-input
                v-model="deliveryForm.card_code"
                placeholder="请输入提现银行卡"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="所属银行" prop="bank_code">
            <div>
              <el-select
                @change="
                  depositList = [];
                  deliveryForm.bank_deposit = '';
                "
                v-model="deliveryForm.bank_code"
                clearable
                filterable
                placeholder="选择所属银行"
              >
                <el-option
                  @click.native="deliveryForm.bank_name = item.bank_name"
                  v-for="item in bankList"
                  :key="item.bank_code"
                  :label="item.bank_name"
                  :value="item.bank_code"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="开户行" prop="bank_deposit">
            <div>
              <el-select
                :remote-method="ajaxGetBranchList"
                :loading="depositLoading"
                v-model="deliveryForm.bank_deposit"
                clearable
                filterable
                remote
                placeholder="选择开户行"
              >
                <el-option
                  v-for="item in depositList"
                  :key="item.code"
                  :label="item.branch_name"
                  :value="item.branch_name"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="持卡人" prop="username">
            <div>
              <el-input
                v-model="deliveryForm.username"
                placeholder="请输入持卡人"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="预留电话" prop="bank_mobile">
            <div>
              <el-input
                v-model="deliveryForm.bank_mobile"
                placeholder="请输入预留电话"
                maxlength="11"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </template>
        <!-- 未升仓的表单项 end -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { UPGRADE_POSITION_ENUM } from "../../utils/enum/index";

export default {
  name: "editDeliveryDialog",
  props: {
    /**
     * 银行列表
     */
    bankList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      depositLoading: false,
      isEdit: false,
      UPGRADE_POSITION_ENUM,
      titleMap: {
        add: {
          value: "add",
          label: "新增自提点",
        },
        edit: {
          value: "edit",
          label: "编辑自提点",
        },
      },
      dialogTitle: "add",
      /** 新增/编辑自提点参数 */
      deliveryForm: {
        logistics_id: "", // 城市仓id
        name: "", // 自提点名称
        address: "", // 自提点地址
        nickname: "", // 账号昵称
        account_number: "", // 登录账号
        password: "", // 密码
        mobile: "", // 联系电话
        freight_parameter: "", // 运费标准
        freight_share: "", // 运费分成
        purchase_parameter: {
          foundation: "",
          foundation_greater: {},
          foundation_less: {},
        }, // 服务费标准对象
        purchase_share: "", // 服务费分成
        card_code: "", // 提现银行卡
        bank_code: "", // 所属银行code
        bank_name: "", // 所属银行名称
        bank_deposit: "", // 开户行
        username: "", // 持卡人
        bank_mobile: "", // 预留电话
        upgrade_position: "", // 是否升仓标识
      },
      cityStoreName: "", // 城市仓名称
      deliveryFormClone: {}, // 拷贝一份数据，用于初始化
      upgradePosition: UPGRADE_POSITION_ENUM.common.value, // 是否已升仓
      businessUserId: "", // 用户id
      businessId: "",
      // 商品单价基点，大于取值
      foundationGreater: {
        symbol: "",
        amount: "",
      },
      // 商品单价基点，小于取值
      foundationLess: {
        symbol: "",
        amount: "",
      },
      depositList: [], // 开户行列表
      submitLoading: false, // 提交按钮禁用
      tableRow: {}, // 当前行数据
      deliveryRules: {
        logistics_id: [
          { required: true, message: "请输入城市仓", trigger: "blur" },
        ],
        name: [
          {
            required: true,
            message: "请输入自提点名称",
            trigger: ["blur", "change"],
          },
        ],
        address: [
          {
            required: true,
            message: "请输入自提点地址",
            trigger: ["blur", "change"],
          },
        ],
        account_number: [
          {
            required: true,
            message: "请输入登录账号",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入联系电话",
            // blur和change事件触发检验
            trigger: ["blur", "change"],
          },
          // 正则判断为字母或数字
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            // 正则检验前先将值转为字符串
            transform(value) {
              return String(value);
            },
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        freight_parameter: [
          {
            required: true,
            message: "请输入运费标准",
            trigger: ["blur", "change"],
          },
        ],
        freight_share: [
          {
            required: true,
            message: "请输入运费分成",
            trigger: ["blur", "change"],
          },
        ],
        /** 商品单价基点，校验 */
        foundation_prop: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              // 遍历对象
              const purchaseParameter = this.deliveryForm.purchase_parameter;
              Object.keys(purchaseParameter).forEach((_KEY) => {
                if (_KEY === "foundation") {
                  !purchaseParameter[_KEY] &&
                    callback(new Error("请输入商品单价基点！"));
                } else {
                  // 遍历子对象
                  const childData = purchaseParameter[_KEY];
                  const list = Object.values(childData);
                  if (!list.length) {
                    callback(new Error("基点条件、金额需填完整！"));
                  }
                  list.forEach((_VAL) => {
                    !_VAL && callback(new Error("基点条件、金额需填完整！"));
                  });
                }
              });
              // 校验运算
              // const { foundation_greater, foundation_less } = purchaseParameter;
              // if (foundation_greater?.amount >= foundation_less?.amount) {
              //   callback(new Error("基点最大值应大于最小值！"));
              // }
              callback();
            },
          },
        ],
        purchase_share: [
          {
            required: true,
            message: "请输入服务费分成",
            trigger: ["blur", "change"],
          },
        ],
        card_code: [
          {
            required: true,
            message: "请输入提现银行卡",
            trigger: ["blur", "change"],
          },
        ],
        bank_code: [
          {
            required: true,
            message: "请选择所属银行",
            trigger: ["blur", "change"],
          },
        ],
        bank_deposit: [
          {
            required: true,
            message: "请输入开户行",
            trigger: ["blur", "change"],
          },
        ],
        username: [
          {
            required: true,
            message: "请输入持卡人",
            trigger: ["blur", "change"],
          },
        ],
        bank_mobile: [
          {
            required: true,
            message: "请输入预留电话",
            // blur和change事件触发检验
            trigger: ["blur", "change"],
          },
          // 正则判断为字母或数字
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            // 正则检验前先将值转为字符串
            // transform(value) {
            //   return String(value);
            // },
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data, row) {
      this.dialogVisible = true;
      this.isEdit = false;
      this.dialogTitle = this.titleMap.add.label;
      this.deliveryFormClone = cloneDeep(this.deliveryForm);
      this.onInitForm(row || data);
      if (data?.module === this.titleMap.edit.value) {
        this.tableRow = row;
        this.dialogTitle = this.titleMap.edit.label;
        this.isEdit = true;
        this.dialogLoading = true;
        this.onInitEditForm(data);
      }
      this.deliveryForm.logistics_id = row?.id || data?.id;
      this.deliveryForm.upgrade_position = this.upgradePosition;
      this.$refs.refDeliveryForm?.clearValidate();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      // console.log("🆒 this.deliveryFormClone", this.deliveryFormClone);
      this.deliveryForm = {
        ...this.deliveryFormClone,
      };
      this.foundationGreater = {
        symbol: "",
        amount: "",
      };
      this.foundationLess = {
        symbol: "",
        amount: "",
      };
      this.$refs.refDeliveryForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 初始化表单
     */
    onInitForm(data) {
      const { upgrade_position, logistics_business, cityStoreName, name } =
        data;
      this.upgradePosition = upgrade_position;
      this.cityStoreName = cityStoreName || name;
      this.businessUserId = logistics_business?.business_user?.id;
    },
    /**
     * 初始化编辑页面
     */
    onInitEditForm(data) {
      // 枚举需要的数据
      const {
        name,
        address,
        mobile,
        freight_parameter,
        freight_share,
        purchase_parameter,
        purchase_share,
      } = data;
      const { id, business_user, bank } = data.business;
      // 基础数据
      const baseParams = {
        name,
        address,
        mobile,
        freight_parameter,
        freight_share,
        account_number: business_user?.username,
        purchase_parameter,
        purchase_share,
      };
      const { foundation_greater, foundation_less } = purchase_parameter;
      this.foundationGreater = foundation_greater;
      this.foundationLess = foundation_less;
      // 升仓数据
      // if (this.upgradePosition === UPGRADE_POSITION_ENUM.common.value) {
      // 升仓所需数据
      const upgradeData = {
        bank_mobile: bank?.mobile,
        ...business_user,
        ...bank,
      };
      // 将数据融入基础数据
      Object.assign(baseParams, upgradeData);
      this.businessUserId = business_user?.id;
      // }
      this.businessId = id;
      // 将表单需要的数据赋值
      Object.keys(this.deliveryForm).forEach((_KEY) => {
        this.deliveryForm[_KEY] = baseParams[_KEY];
        // this.$set(this.deliveryForm, _KEY, baseParams[_KEY]);
      });
      // console.log("🆒 onInitEditForm", this.deliveryForm);
      this.deliveryForm.id = data.id;
      this.dialogLoading = false;
    },
    /**
     * 获得开户行列表
     */
    async ajaxGetBranchList(query) {
      this.depositList = [];
      if (!query) {
        return;
      }
      this.depositLoading = true;
      const params = {
        bank_code: this.deliveryForm.bank_code,
        branch_name: query,
      };
      try {
        const { data } = await this.$api.general.branchList(params);
        this.depositList = data;
      } catch (err) {
        console.log("ajax branchList err", err);
      } finally {
        this.depositLoading = false;
      }
    },
    /**
     * 重置密码，二次确认
     */
    onHandleResetPassword() {
      this.$confirm("确定要重置密码吗？", "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostResetPassword();
      });
    },
    /**
     * 重置密码请求接口
     */
    async ajaxPostResetPassword() {
      if (!this.businessUserId) {
        return;
      }
      const params = {
        business_user_id: this.businessUserId,
      };
      try {
        await this.$api.general.resetPassword(params);
        this.$message.success("重置成功");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax resetPassword err", err);
      }
    },
    /**
     * 修改了商品单价基点
     */
    onChangeSymbol(val) {
      // 重置数据
      if (val == "foundationGreater") {
        this.foundationLess.symbol = "";
      } else {
        this.foundationGreater.symbol = "";
      }
      // 修改运算
      switch (this.foundationGreater.symbol) {
        case ">":
          this.foundationLess.symbol = "<=";
          break;
        case ">=":
          this.foundationLess.symbol = "<";
          break;
      }
      switch (this.foundationLess.symbol) {
        case "<":
          this.foundationGreater.symbol = ">=";
          break;
        case "<=":
          this.foundationGreater.symbol = ">";
          break;
      }
      this.deliveryForm.purchase_parameter.foundation_greater =
        this.foundationGreater;
      this.deliveryForm.purchase_parameter.foundation_less =
        this.foundationLess;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refDeliveryForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        if (this.isEdit) {
          this.ajaxPostUpdateDelivery();
        } else {
          this.ajaxPostAddDelivery();
        }
      });
    },
    /**
     * 新增自提点
     */
    async ajaxPostAddDelivery() {
      const params = {
        ...this.deliveryForm,
      };
      try {
        await this.$api.general.addDelivery(params);
        this.$message.success("新增成功~");
        this.onHandleClose();
        this.$emit("on-add-delivery", params.logistics_id);
      } catch (err) {
        console.log("ajax ajaxPostAddDelivery err", err);
      } finally {
        this.submitLoading = false;
      }
    },
    /**
     * 编辑自提点
     */
    async ajaxPostUpdateDelivery() {
      const params = {
        ...this.deliveryForm,
        business_id: this.businessId,
      };
      delete params.logistics_id;
      try {
        await this.$api.general.updateDelivery(params);
        this.$message.success("修改成功~");
        const emitData = {
          ...this.deliveryForm,
          // 转查列表需要的id，默认最小自提点个数，跳过验证
          id: this.deliveryForm.logistics_id,
          delivery_number: 1,
          cityStoreName: this.cityStoreName,
        };
        this.onHandleClose();
        this.$emit("on-edit-delivery", emitData);
      } catch (err) {
        console.log("ajax ajaxPostUpdateDelivery err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modules-wrap {
  padding: 6px;
}
</style>
