<template>
  <div class="page-wrap">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <!-- 总控增加导出功能 start -->
        <el-button
          @click="onHandleExport"
          :disabled="!tableData.length || disabledExport"
          type="warning"
          icon="el-icon-download"
          >导 出</el-button
        >
        <!-- 总控增加导出功能 end -->
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="城市仓名称"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.name || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="集配业务中心"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
              (scope.row.business && scope.row.business.name) || "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="delivery_number"
          align="center"
          label="自提点数量"
        >
          <template slot-scope="scope">
            {{ scope.row.delivery_number }}
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.status === DISABLED_ENUM.unDisabled.value"
              >启用</el-tag
            >
            <el-tag type="warning" v-else>禁用</el-tag>
          </template>
        </el-table-column>

        <!-- 列表操作栏 start -->
        <el-table-column label="操作" align="center" min-width="150">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                v-if="
                  scope.row.upgrade_position ===
                  UPGRADE_POSITION_ENUM.common.value
                "
                @click="onHandleUpStorage(scope.row)"
                >升仓</el-button
              >
              <el-button type="text" @click="onHandleEditData(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="onHandleDisabled(scope.row)">{{
                scope.row.status === DISABLED_ENUM.unDisabled.value
                  ? "禁用"
                  : "启用"
              }}</el-button>
            </div>
            <el-button type="text" @click="onHandleDeliverDetail(scope.row)"
              >查看自提点</el-button
            >
            <el-button type="text" @click="onHandleAddDeliver(scope.row)"
              >新增自提点</el-button
            >
          </template>
        </el-table-column>
        <!-- 列表操作栏 end -->
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 升仓 start -->
    <upgrade-store-dialog
      ref="refUpgradeStore"
      :bankList="bankList"
      @on-show-image="onHandleChangeData"
      @on-refresh-list="ajaxGetTableList"
    ></upgrade-store-dialog>
    <!-- 升仓 end -->

    <!-- 新增/修改城市仓 start -->
    <edit-store-dialog
      ref="refEditStore"
      :logisticsList="logisticsList"
      :bankList="bankList"
      @on-show-image="onHandleChangeData"
      @on-refresh-list="ajaxGetTableList"
    ></edit-store-dialog>
    <!-- 新增/修改城市仓 end -->

    <!-- 新增/编辑自提点 start -->
    <edit-delivery-dialog
      ref="refEditDelivery"
      :bankList="bankList"
      @on-add-delivery="onHandleAddDelivery"
      @on-edit-delivery="onHandleDeliverDetail"
    ></edit-delivery-dialog>
    <!-- 新增/编辑自提点 end -->

    <!-- 查看自提点 start -->
    <delivery-list-dialog
      ref="refDeliveryList"
      @on-edit-delivery="onHandleEditDelivery"
    ></delivery-list-dialog>
    <!-- 查看自提点 end -->

    <!-- 上传图片预览 start -->
    <el-dialog :visible.sync="showImage">
      <img :src="bigImage" alt="" class="showImg" />
    </el-dialog>
    <!-- 上传图片预览 end -->
  </div>
</template>
<script>
import { UPGRADE_POSITION_ENUM, DISABLED_ENUM } from "./utils/enum/index";
import UpgradeStoreDialog from "./modules/upgrade-store-dialog/index";
import EditStoreDialog from "./modules/edit-store-dialog/index";
import EditDeliveryDialog from "./modules/edit-delivery-dialog/index";
import DeliveryListDialog from "./modules/delivery-list-dialog/index";
import { postLogisticsListExport } from "@/api/export/center";

export default {
  name: "SelfSupportStore",
  components: {
    UpgradeStoreDialog,
    EditStoreDialog,
    EditDeliveryDialog,
    DeliveryListDialog,
  },
  data() {
    return {
      loading: false,
      disabledExport: false, // 导出禁用
      UPGRADE_POSITION_ENUM,
      DISABLED_ENUM,
      upgrade_position: UPGRADE_POSITION_ENUM.common.value, // 是否升仓
      formData: {
        name: "", // 城市仓名称
        logisticBusinessId: "", // 集配中心id
        selfManagement: 1,
        page: 1,
        pageSize: 10,
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      bigImage: "", // 图片地址
      showImage: false, // 是否展示图片
      logisticsList: [], // 集配中心列表
      bankList: [], // 银行列表
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化
     */
    onInitData() {
      this.getAjaxLogisticsList();
      this.ajaxGetTableList();
      this.ajaxGetBankList();
    },
    /**
     * 将子组件值传给父组件
     */
    onHandleChangeData(data) {
      Object.keys(data).forEach((_key) => {
        this[_key] = data[_key];
      });
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 获取银行列表
     */
    async ajaxGetBankList() {
      try {
        const { data } = await this.$api.general.banksList();
        this.bankList = data;
      } catch (err) {
        console.log("ajax banksList err", err);
      }
    },
    /**
     * 获得城市仓列表
     */
    async ajaxGetTableList() {
      this.loading = true;
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.log("ajax logisticsList err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.ajaxGetTableList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableList();
    },
    /**
     * 城市仓启用/禁用，二次确认
     */
    onHandleDisabled(row) {
      const msg =
        row.status === DISABLED_ENUM.unDisabled.value
          ? "确定要禁用吗？"
          : "确定要启用吗？";
      this.$confirm(msg, "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostLogisticsStatus(row);
      });
    },
    /**
     * 城市仓启用/禁用，接口请求
     */
    async ajaxPostLogisticsStatus(row) {
      const status =
        row.status === DISABLED_ENUM.unDisabled.value
          ? DISABLED_ENUM.disabled.value
          : DISABLED_ENUM.unDisabled.value;
      const params = {
        logistics_id: row.id,
        status,
      };
      try {
        await this.$api.general.logisticsStatus(params);
        // 成功后修改值，减少一次接口请求
        const rowIndex = this.tableData.findIndex((item) => item.id === row.id);
        this.tableData[rowIndex].status = status;
        const msg =
          row.status === DISABLED_ENUM.disabled.value ? "禁用成功" : "启用成功";
        this.$message.success(msg);
      } catch (err) {
        console.log("ajax logisticsStatus err", err);
      }
    },
    /**
     * 升仓操作
     */
    onHandleUpStorage(data) {
      const { name, id } = data;
      const params = {
        name,
        id,
      };
      this.$refs.refUpgradeStore.onInitData(params);
    },
    /**
     * 编辑城市仓
     */
    onHandleEditData(row) {
      this.$refs.refEditStore.onInitData(row);
    },
    /**
     * 新增自提点
     */
    onHandleAddDeliver(row) {
      const params = {
        module: "add",
        ...row,
      };
      this.$refs.refEditDelivery.onInitData(params);
    },
    /**
     * 编辑自提点
     */
    onHandleEditDelivery(data, row) {
      const params = {
        module: "edit",
        ...data,
      };
      this.$refs.refEditDelivery.onInitData(params, row);
    },
    /**
     * 新增自提点，成功后更新数量，减少一次接口
     */
    onHandleAddDelivery(data) {
      const index = this.tableData.findIndex((item) => item.id === data);
      this.tableData[index].delivery_number++;
    },
    /**
     * 查看自提点
     */
    onHandleDeliverDetail(row) {
      // console.log("🆒 onHandleDeliverDetail", row);
      if (!row.delivery_number) {
        this.$message.warning("该城市仓下暂无自提点~");
        return;
      }
      this.$refs.refDeliveryList.onInitData(row);
    },
    /**
     * 导出
     */
    async onHandleExport() {
      this.disabledExport = true;
      this.$message.success({
        message: "正在导出，请耐心等待",
        duration: 9999,
      });
      try {
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postLogisticsListExport(query);
        this.$message.success("导出成功，请去下载中心查看！");
      } catch (err) {
        console.log("ajax err", err);
      } finally {
        this.disabledExport = false;
        this.$message.closeAll();
      }
    },
  },
};
</script>
<style lang="scss">
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .showImg {
    text-align: center;
    height: 76vh;
  }
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
  }
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
