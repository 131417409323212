<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        class="demo-cityForm"
        label-width="170px"
        ref="refCityForm"
        v-loading="dialogLoading"
        :model="cityForm"
        :rules="cityRules"
      >
        <!-- 非升仓表单 start -->
        <el-form-item label="城市仓名称" prop="name">
          <div>
            <el-input
              v-model="cityForm.name"
              placeholder="请输入城市仓名称"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="集配业务中心" prop="business_id">
          <div>
            <el-select
              v-model="cityForm.business_id"
              clearable
              placeholder="选择业务中心"
            >
              <el-option
                v-for="item in logisticsList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="城市仓类型" prop="logistics_type">
          <div>
            <el-select
              v-model="cityForm.logistics_type"
              :disabled="isEdit"
              clearable
              placeholder="选择城市仓类型"
            >
              <el-option
                v-for="item of logisticsTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item v-if="isEdit" label="自提点数量" prop="delivery_number">
          <div>
            <el-input
              :disabled="true"
              v-model="cityForm.delivery_number"
              placeholder="请输入自提点数量"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="截单时间" prop="dateTime">
          <div class="d-flex a-center">
            <div>
              <el-time-picker
                v-model="cityForm.startTime"
                format="HH:mm"
                value-format="HH:mm"
                placeholder="开始时间"
              >
              </el-time-picker>
            </div>
            <div>—</div>
            <div>
              <el-time-picker
                v-model="cityForm.endTime"
                format="HH:mm"
                value-format="HH:mm"
                placeholder="结束时间"
              >
              </el-time-picker>
            </div>
          </div>
        </el-form-item>
        <!-- 非升仓表单 end -->

        <!-- 升仓后的表单 start -->
        <template
          v-if="upgradePosition === UPGRADE_POSITION_ENUM.upgrade.value"
        >
          <el-form-item label="账号昵称">
            <div>
              <el-input
                v-model="cityForm.nickname"
                placeholder="请输入账号昵称"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="登录账号" prop="account_number">
            <div>
              <el-input
                :disabled="true"
                v-model="cityForm.account_number"
                placeholder="请输入登录账号"
                clearable
              >
                <el-button
                  type="text"
                  slot="suffix"
                  :disabled="!businessUserId"
                  @click="onHandleResetPassword"
                  >重置密码</el-button
                >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="提现银行卡" prop="card_code">
            <div>
              <el-input
                v-model="cityForm.card_code"
                placeholder="请输入提现银行卡"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="所属银行" prop="bank_code">
            <div>
              <el-select
                @change="
                  depositList = [];
                  cityForm.bank_deposit = '';
                "
                v-model="cityForm.bank_code"
                clearable
                filterable
                placeholder="选择所属银行"
              >
                <el-option
                  @click.native="cityForm.bank_name = item.bank_name"
                  v-for="item in bankList"
                  :key="item.bank_code"
                  :label="item.bank_name"
                  :value="item.bank_code"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="开户行" prop="bank_deposit">
            <div>
              <el-select
                :remote-method="ajaxGetBranchList"
                :loading="depositLoading"
                v-model="cityForm.bank_deposit"
                clearable
                filterable
                remote
                placeholder="选择开户行"
              >
                <el-option
                  v-for="item in depositList"
                  :key="item.code"
                  :label="item.branch_name"
                  :value="item.branch_name"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="持卡人" prop="username">
            <div>
              <el-input
                v-model="cityForm.username"
                placeholder="请输入持卡人"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="预留电话" prop="bank_mobile">
            <div>
              <el-input
                v-model="cityForm.bank_mobile"
                placeholder="请输入预留电话"
                maxlength="11"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="客服电话" prop="service_mobile">
            <div>
              <el-input
                v-model="cityForm.service_mobile"
                :maxlength="11"
                placeholder="请输入客服电话"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="身份证国徽页图片" prop="riseIdCardA">
            <el-upload
              action="#"
              v-loading="uploadLoading"
              :on-preview="onHandlePictureCardPreview"
              :class="{ hide: riseIdCardA.length || !riseIdCardA }"
              :file-list="riseIdCardA"
              list-type="picture-card"
              :http-request="(data) => onHandleUploadFile(data, 'riseIdCardA')"
              :before-remove="() => onHandleDeleteFile('riseIdCardA')"
              :limit="1"
            >
              <i slot="trigger" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="身份证人像页图片" prop="riseIdCardB">
            <el-upload
              action="#"
              v-loading="uploadLoading"
              :on-preview="onHandlePictureCardPreview"
              :class="{ hide: riseIdCardB.length || !riseIdCardB }"
              :file-list="riseIdCardB"
              list-type="picture-card"
              :http-request="(data) => onHandleUploadFile(data, 'riseIdCardB')"
              :before-remove="() => onHandleDeleteFile('riseIdCardB')"
              :limit="1"
            >
              <i slot="trigger" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="运距" prop="shipment_distance">
            <div>
              <el-input
                class="distance-style"
                v-model="cityForm.shipment_distance"
                @blur="
                  () =>
                    (cityForm.shipment_distance = utilsTool.filterUnNumber(
                      cityForm.shipment_distance
                    ))
                "
                placeholder="请输入运距"
                clearable
              ></el-input>
              公里
            </div>
          </el-form-item>
        </template>
        <!-- 升仓后的表单 end -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import utilsTool from "@/utils/tools/tool";
import { formatDate } from "@/utils//tools/date.js";
import {
  LOGISTICS_TYPE_ENUM,
  UPGRADE_POSITION_ENUM,
} from "../../utils/enum/index";

export default {
  name: "EditStoreDialog",
  props: {
    /**
     * 集配中心列表
     */
    logisticsList: {
      type: Array,
      default: () => [],
    },
    /**
     * 银行列表
     */
    bankList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      utilsTool,
      titleMap: {
        add: {
          value: "add",
          label: "新增城市仓",
        },
        edit: {
          value: "edit",
          label: "编辑城市仓",
        },
      },
      dialogTitle: "",
      UPGRADE_POSITION_ENUM,
      loadImage: null,
      depositLoading: false,
      depositList: [], // 开户行列表
      riseIdCardA: [], // 身份证国徽图片
      riseIdCardB: [], // 身份证人像图片
      // 城市仓参数
      cityForm: {
        name: "", // 城市仓名称
        business_id: "", // 集配中心id
        logistics_type: LOGISTICS_TYPE_ENUM.common.value, // 城市仓类型
        delivery_number: "", // 自提点数量
        startTime: "", // 截单开始时间
        endTime: "", // 截单结束时间
        nickname: "", // 账号昵称
        account_number: "", // 登录账号
        card_code: "", // 提现银行卡
        bank_code: "", // 所属银行code
        bank_name: "", // 所属银行名称
        bank_deposit: "", // 开户行
        username: "", // 持卡人
        bank_mobile: "", // 预留电话
        riseIdCardA: "", // 身份证国徽页
        riseIdCardB: "", // 身份证人像图片
        service_mobile: "", // 客服电话
        shipment_distance: "", // 运距
      },
      cityFormClone: {}, // 拷贝一份数据，用于初始化
      logisticsBusinessId: "", // 城市仓id
      uploadLoading: false, // 上传loading
      submitLoading: false, // 提交按钮禁用
      logisticsTypeList: Object.values(LOGISTICS_TYPE_ENUM), // 城市仓类型列表
      upgradePosition: UPGRADE_POSITION_ENUM.common.value, // 是否已升仓
      // 表单校验
      cityRules: {
        name: [
          {
            required: true,
            message: "请输入城市仓名称",
            trigger: ["blur", "change"],
          },
        ],
        business_id: [
          { required: true, message: "请选择集采业务中心", trigger: "change" },
        ],
        /** 时间校验 */
        dateTime: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              const { startTime, endTime } = this.cityForm;
              if (!startTime || !endTime) {
                callback(new Error("截单时间须填完整！"));
              }
              // 将时间转换成时间戳，比较大小
              let start = `${formatDate()} ${startTime}:00`;
              let end = `${formatDate()} ${endTime}:00`;
              const min = new Date(start).getTime();
              const max = new Date(end).getTime();
              // console.log("🆒 dateTime", min, max);
              if (min > max) {
                callback(new Error("结束时间不得早于开始时间！"));
              }
              callback();
            },
          },
        ],
        account_number: [
          {
            required: true,
            message: "请输入登录账号",
            trigger: ["blur", "change"],
          },
        ],
        card_code: [
          {
            required: true,
            message: "请输入提现银行卡",
            trigger: ["blur", "change"],
          },
        ],
        bank_code: [
          {
            required: true,
            message: "请选择所属银行",
            trigger: ["blur", "change"],
          },
        ],
        bank_deposit: [
          {
            required: true,
            message: "请输入开户行",
            trigger: ["blur", "change"],
          },
        ],
        username: [
          {
            required: true,
            message: "请输入持卡人",
            trigger: ["blur", "change"],
          },
        ],
        bank_mobile: [
          {
            required: true,
            message: "请输入预留电话",
            trigger: ["blur", "change"],
          },
          // 正则判断为字母或数字
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        riseIdCardA: [
          {
            required: true,
            message: "请上传身份证国徽页图片",
            trigger: ["blur", "change"],
          },
        ],
        riseIdCardB: [
          {
            required: true,
            message: "请上传身份证人像页图片",
            trigger: ["blur", "change"],
          },
        ],
        /** 客服电话 */
        service_mobile: [
          {
            required: true,
            message: "请输入客服电话",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        /** 运距 */
        shipment_distance: [
          {
            required: true,
            message: "请输入运距",
            trigger: ["blur", "change"],
          },
        ],
      },
      isEdit: false, // 是否编辑页
      businessUserId: "", // 用户id
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.isEdit = false;
      this.dialogTitle = this.titleMap.add.label;
      this.upgradePosition = UPGRADE_POSITION_ENUM.common.value;
      this.riseIdCardA = [];
      this.riseIdCardB = [];
      this.cityFormClone = cloneDeep(this.cityForm);
      if (data?.id) {
        this.dialogTitle = this.titleMap.edit.label;
        this.isEdit = true;
        this.dialogLoading = true;
        this.onInitEditForm(data);
      }
      this.$refs.refCityForm?.clearValidate();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.cityForm = {
        ...this.cityFormClone,
      };
      this.$refs.refCityForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 初始化编辑页面
     */
    onInitEditForm(data) {
      // 枚举需要的数据
      const {
        name,
        business_id,
        logistics_type,
        delivery_number,
        upgrade_position,
        service_mobile,
        shipment_distance,
        truncation_order: { truncation_time },
      } = data;
      // 基础数据
      const baseParams = {
        name,
        logistics_type,
        delivery_number,
        startTime: truncation_time?.start_time,
        endTime: truncation_time?.end_time,
      };
      this.upgradePosition = upgrade_position;
      // 已升仓数据
      if (this.upgradePosition === UPGRADE_POSITION_ENUM.upgrade.value) {
        const {
          logistics_business: { id, business_user, bank },
        } = data;
        // 升仓所需数据
        const upgradeData = {
          account_number: business_user?.username,
          bank_mobile: bank?.mobile,
          ...business_user,
          ...bank,
          service_mobile,
          shipment_distance,
        };
        // 将数据融入基础数据
        Object.assign(baseParams, upgradeData);
        this.logisticsBusinessId = id;
        this.businessUserId = business_user?.id;
      }
      baseParams.business_id = business_id;
      // 将表单需要的数据赋值
      Object.keys(this.cityForm).forEach((_KEY) => {
        this.cityForm[_KEY] = baseParams[_KEY];
        // this.$set(this.cityForm, _KEY, baseParams[_KEY]);
      });
      console.log("🆒 onInitEditForm", baseParams);
      this.cityForm.id = data.id;
      this.riseIdCardA = this.cityForm.riseIdCardA =
        (baseParams?.idcard_a && [
          {
            url: baseParams.idcard_a,
          },
        ]) ||
        [];
      this.riseIdCardB = this.cityForm.riseIdCardB =
        (baseParams?.idcard_b && [
          {
            url: baseParams.idcard_b,
          },
        ]) ||
        [];
      this.dialogLoading = false;
    },
    /**
     * 获得开户行列表
     */
    async ajaxGetBranchList(query) {
      this.depositList = [];
      if (!query) {
        return;
      }
      this.depositLoading = true;
      const params = {
        bank_code: this.cityForm.bank_code,
        branch_name: query,
      };
      try {
        const { data } = await this.$api.general.branchList(params);
        this.depositList = data;
      } catch (err) {
        console.log("ajax branchList err", err);
      } finally {
        this.depositLoading = false;
      }
    },
    /**
     * 删除图片
     */
    onHandleDeleteFile(name) {
      this[name] = [];
      this.cityForm[name] = "";
      this.$refs.refCityForm.validateField(name);
    },
    /**
     * 上传图片
     */
    onHandleUploadFile(options, name) {
      this.uploadLoading = true;
      // 拿到 file
      let file = options.file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // console.log(e.target.result);
        this.ajaxUploadImage(e.target.result, name, options.file.uid);
      };
    },
    /**
     * 图片路径请求
     */
    async ajaxUploadImage(img, name, uid) {
      try {
        const res = await this.$api.general.uploadImage({ file_data: img });
        // 增加uid 赋值 解决上传图片跳动问题
        this[name] = [{ url: res.data, uid }];
        this.cityForm[name] = res.data;
        this.$refs.refCityForm.validateField(name);
      } catch (err) {
      } finally {
        this.uploadLoading = false;
      }
    },
    /**
     * 预览上传图片
     */
    onHandlePictureCardPreview(file) {
      const params = {
        bigImage: file.url,
        showImage: true,
      };
      this.$emit("on-show-image", params);
    },
    /**
     * 重置密码，二次确认
     */
    onHandleResetPassword() {
      this.$confirm("确定要重置密码吗？", "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostResetPassword();
      });
    },
    /**
     * 重置密码请求接口
     */
    async ajaxPostResetPassword() {
      if (!this.businessUserId) {
        return;
      }
      const params = {
        business_user_id: this.businessUserId,
      };
      try {
        await this.$api.general.resetPassword(params);
        this.$message.success("重置成功");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax resetPassword err", err);
      }
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refCityForm.validate((valid) => {
        if (!valid) {
          return;
        }
        /** 触发提交前校验对象 */
        const validObject = {
          startTime: {
            valid: !this.cityForm.startTime,
            message: "请选择开始时间",
          },
          endTime: {
            valid: !this.cityForm.endTime,
            message: "请选择结束时间",
          },
        };
        // 循环校验对象
        Object.values(validObject).forEach((item) => {
          if (item.valid) {
            this.$message.error(item.message);
            return;
          }
        });
        this.submitLoading = true;
        if (this.isEdit) {
          this.ajaxPostUpdateLogistics();
        } else {
          this.ajaxPostAddLogistics();
        }
      });
    },
    /**
     * 新增城市仓
     */
    async ajaxPostAddLogistics() {
      const { name, logistics_type, business_id, startTime, endTime } =
        this.cityForm;
      const params = {
        name,
        logistics_type,
        business_id,
        truncation_time: {
          start_time: startTime,
          end_time: endTime,
        },
      };
      try {
        await this.$api.general.addLogistics(params);
        this.$message.success("新增成功");
        this.onHandleClose();
        this.$emit("on-refresh-list");
      } catch (err) {
        console.log("ajax addLogistics err", err);
      } finally {
        this.submitLoading = false;
      }
    },
    /**
     * 编辑城市仓
     */
    async ajaxPostUpdateLogistics() {
      const { startTime, endTime } = this.cityForm;
      const params = {
        ...this.cityForm,
        logistics_business_id: this.logisticsBusinessId,
        truncation_time: {
          start_time: startTime,
          end_time: endTime,
        },
      };
      // 已升仓的参数
      if (this.upgradePosition === UPGRADE_POSITION_ENUM.upgrade.value) {
        params.idcard_a = params.riseIdCardA?.[0]?.url || params.riseIdCardA;
        params.idcard_b = params.riseIdCardB?.[0]?.url || params.riseIdCardB;
      }
      delete params.startTime;
      delete params.endTime;
      delete params.riseIdCardA;
      delete params.riseIdCardB;
      console.log("🆒 ajaxPostUpdateLogistics params", params);
      try {
        await this.$api.general.updateLogistics(params);
        this.$message.success("修改成功");
        this.onHandleClose();
        this.$emit("on-refresh-list");
      } catch (err) {
        console.log("ajax updateLogistics err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.distance-style {
  width: 80%;
}
</style>
