<template>
  <el-dialog
    title="查看自提点"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="75%"
    top="5vh"
    center
  >
    <div class="seach">
      <span class="label">搜索自提点：</span>
      <div class="inputs">
        <el-input
          @input="onChange"
          v-model="formData.delivery_name"
          placeholder="自提点名称"
          clearable
        ></el-input>
      </div>
    </div>

    <div
      class="row-box d-flex flex-column"
      style="flex-grow: 1; overflow: hidden"
      v-loading="dialogLoading"
    >
      <el-table
        :data="tableData"
        height="100%"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="自提点名称"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.name || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="自提点地址"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.address || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="联系电话">
          <template slot-scope="scope">
            {{ scope.row.mobile || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="freight_parameter"
          align="center"
          label="运费标准(元)"
        >
          <template slot-scope="scope">
            {{ scope.row.freight_parameter }}
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            {{
              scope.row.status === DISABLED_ENUM.unDisabled.value
                ? "启用"
                : "禁用"
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="onHandleEditData(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="onHandleDisabled(scope.row)">{{
              scope.row.status === DISABLED_ENUM.unDisabled.value
                ? "禁用"
                : "启用"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pagination-position">
        <el-pagination
          type="primary"
          background
          @size-change="onHandleSizeChange"
          @current-change="onHandleCurrentChange"
          :current-page="formData.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="formData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <template #footer>
      <el-button type="primary" @click="onHandleClose">返回</el-button>
    </template>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { DISABLED_ENUM } from "../../utils/enum/index";

export default {
  name: "DeliveryListDialog",
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      DISABLED_ENUM,
      /** 查询参数 */
      formData: {
        logistics_id: "",
        delivery_name: "",
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      tableData: [],
      total: 0,
      tableRow: {}, // 当前行数据
    };
  },
  methods: {
    /**
     * 搜索自提点
     */
    onChange: debounce(async function (e) {
      this.formData.page = 1;
      this.ajaxGetDeliveryList();
    }, 500),
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.formData.logistics_id = data.id;
      this.tableRow = data;
      this.ajaxGetDeliveryList();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
      this.formData = {
        ...this.formDataClone,
      };
    },
    /**
     * 获取自提点列表
     */
    async ajaxGetDeliveryList() {
      this.dialogLoading = true;
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await this.$api.general.deliveryList(params);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.log("ajax ajaxGetDeliveryList err", err);
      } finally {
        this.dialogLoading = false;
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.ajaxGetDeliveryList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetDeliveryList();
    },
    /**
     * 自提点启用/禁用，二次确认
     */
    onHandleDisabled(row) {
      const msg =
        row.status === DISABLED_ENUM.unDisabled.value
          ? "确定要禁用吗？"
          : "确定要启用吗？";
      this.$confirm(msg, "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostDeliveryStatus(row);
      });
    },
    /**
     * 自提点启用/禁用，接口请求
     */
    async ajaxPostDeliveryStatus(row) {
      const status =
        row.status === DISABLED_ENUM.unDisabled.value
          ? DISABLED_ENUM.disabled.value
          : DISABLED_ENUM.unDisabled.value;
      const params = {
        delivery_id: row.id,
        status,
      };
      try {
        await this.$api.general.deliveryStatus(params);
        // 成功后修改值，减少一次接口请求
        const rowIndex = this.tableData.findIndex((item) => item.id === row.id);
        this.tableData[rowIndex].status = status;
        const msg =
          row.status === DISABLED_ENUM.disabled.value ? "禁用成功" : "启用成功";
        this.$message.success(msg);
      } catch (err) {
        console.log("ajax ajaxPostDeliveryStatus err", err);
      }
    },
    /**
     * 编辑自提点
     */
    onHandleEditData(row) {
      this.$emit("on-edit-delivery", row, this.tableRow);
    },
  },
};
</script>

<style lang="scss" scoped>
.modules-wrap {
  padding: 6px;
}
/deep/ .el-dialog {
  height: calc(100vh - 5vh);
  display: flex;
  flex-direction: column;
}
/deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  padding: 10px 20px !important;
}
.seach {
  display: flex;
  // flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
  .inputs {
    width: 207px;
  }
}
</style>
