/** 城市仓类型枚举 */
export const LOGISTICS_TYPE_ENUM = {
  common: {
    value: 1,
    label: '普通仓'
  },
  member: {
    value: 2,
    label: '大客户仓'
  }
}

/** 升仓标识枚举 */
export const UPGRADE_POSITION_ENUM = {
  common: {
    value: 1,
    label: '未升仓'
  },
  upgrade: {
    value: 2,
    label: '已升仓'
  }
}

/** 禁用枚举 */
export const DISABLED_ENUM = {
  disabled: {
    value: 0,
    label: '已禁用'
  },
  unDisabled: {
    value: 1,
    label: '已启用'
  }
}
