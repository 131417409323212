var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "升仓",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "50%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "refStorageForm",
          staticClass: "contents",
          attrs: {
            "label-width": "170px",
            model: _vm.storageForm,
            rules: _vm.storageRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "城市仓", prop: "logistics_id" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: {
                      disabled: true,
                      placeholder: "请输入城市仓",
                      clearable: "",
                    },
                    model: {
                      value: _vm.deliveryName,
                      callback: function ($$v) {
                        _vm.deliveryName = $$v
                      },
                      expression: "deliveryName",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账号昵称", prop: "nickname" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入账号昵称", clearable: "" },
                    model: {
                      value: _vm.storageForm.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.storageForm, "nickname", $$v)
                      },
                      expression: "storageForm.nickname",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "登录账号", prop: "account_number" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入登录账号", clearable: "" },
                    model: {
                      value: _vm.storageForm.account_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.storageForm, "account_number", $$v)
                      },
                      expression: "storageForm.account_number",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("el-form-item", { attrs: { label: "密码", prop: "password" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: {
                    type: "password",
                    placeholder: "请输入密码",
                    clearable: "",
                  },
                  model: {
                    value: _vm.storageForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.storageForm, "password", $$v)
                    },
                    expression: "storageForm.password",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { label: "地址", prop: "address" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入地址", clearable: "" },
                  model: {
                    value: _vm.storageForm.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.storageForm, "address", $$v)
                    },
                    expression: "storageForm.address",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "提现银行卡", prop: "card_code" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入提现银行卡", clearable: "" },
                    model: {
                      value: _vm.storageForm.card_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.storageForm, "card_code", $$v)
                      },
                      expression: "storageForm.card_code",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属银行", prop: "bank_code" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "选择所属银行",
                      },
                      on: {
                        change: function ($event) {
                          _vm.depositList = []
                          _vm.storageForm.bank_deposit = ""
                        },
                      },
                      model: {
                        value: _vm.storageForm.bank_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.storageForm, "bank_code", $$v)
                        },
                        expression: "storageForm.bank_code",
                      },
                    },
                    _vm._l(_vm.bankList, function (item) {
                      return _c("el-option", {
                        key: item.bank_code,
                        attrs: { label: item.bank_name, value: item.bank_code },
                        nativeOn: {
                          click: function ($event) {
                            _vm.storageForm.bank_name = item.bank_name
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户行", prop: "bank_deposit" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "remote-method": _vm.ajaxGetBranchList,
                        loading: _vm.depositLoading,
                        clearable: "",
                        filterable: "",
                        remote: "",
                        placeholder: "选择开户行",
                      },
                      model: {
                        value: _vm.storageForm.bank_deposit,
                        callback: function ($$v) {
                          _vm.$set(_vm.storageForm, "bank_deposit", $$v)
                        },
                        expression: "storageForm.bank_deposit",
                      },
                    },
                    _vm._l(_vm.depositList, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: {
                          label: item.branch_name,
                          value: item.branch_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("el-form-item", { attrs: { label: "持卡人", prop: "username" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入持卡人", clearable: "" },
                  model: {
                    value: _vm.storageForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.storageForm, "username", $$v)
                    },
                    expression: "storageForm.username",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "预留电话", prop: "bank_mobile" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入预留电话",
                      maxlength: "11",
                      clearable: "",
                    },
                    model: {
                      value: _vm.storageForm.bank_mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.storageForm, "bank_mobile", $$v)
                      },
                      expression: "storageForm.bank_mobile",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客服电话", prop: "service_mobile" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 11,
                      placeholder: "请输入客服电话",
                      clearable: "",
                    },
                    model: {
                      value: _vm.storageForm.service_mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.storageForm, "service_mobile", $$v)
                      },
                      expression: "storageForm.service_mobile",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证国徽页图片", prop: "riseIdCardA" } },
            [
              _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.uploadLoading,
                      expression: "uploadLoading",
                    },
                  ],
                  class: { hide: _vm.riseIdCardA.length || !_vm.riseIdCardA },
                  attrs: {
                    action: "#",
                    "on-preview": _vm.onHandlePictureCardPreview,
                    "file-list": _vm.riseIdCardA,
                    "list-type": "picture-card",
                    "http-request": (data) =>
                      _vm.onHandleUploadFile(data, "riseIdCardA"),
                    "before-remove": () =>
                      _vm.onHandleDeleteFile("riseIdCardA"),
                    limit: 1,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "trigger" },
                    slot: "trigger",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证人像页图片", prop: "riseIdCardB" } },
            [
              _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.uploadLoading,
                      expression: "uploadLoading",
                    },
                  ],
                  class: { hide: _vm.riseIdCardB.length || !_vm.riseIdCardB },
                  attrs: {
                    action: "#",
                    "on-preview": _vm.onHandlePictureCardPreview,
                    "file-list": _vm.riseIdCardB,
                    "list-type": "picture-card",
                    "http-request": (data) =>
                      _vm.onHandleUploadFile(data, "riseIdCardB"),
                    "before-remove": () =>
                      _vm.onHandleDeleteFile("riseIdCardB"),
                    limit: 1,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "trigger" },
                    slot: "trigger",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "运距", prop: "shipment_distance" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "distance-style",
                    attrs: { placeholder: "请输入运距", clearable: "" },
                    on: {
                      blur: () =>
                        (_vm.storageForm.shipment_distance =
                          _vm.utilsTool.filterUnNumber(
                            _vm.storageForm.shipment_distance
                          )),
                    },
                    model: {
                      value: _vm.storageForm.shipment_distance,
                      callback: function ($$v) {
                        _vm.$set(_vm.storageForm, "shipment_distance", $$v)
                      },
                      expression: "storageForm.shipment_distance",
                    },
                  }),
                  _vm._v(" 公里 "),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }