var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "50%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              ref: "refCityForm",
              staticClass: "demo-cityForm",
              attrs: {
                "label-width": "170px",
                model: _vm.cityForm,
                rules: _vm.cityRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "城市仓名称", prop: "name" } },
                [
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入城市仓名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.cityForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.cityForm, "name", $$v)
                          },
                          expression: "cityForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "集配业务中心", prop: "business_id" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "选择业务中心" },
                          model: {
                            value: _vm.cityForm.business_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.cityForm, "business_id", $$v)
                            },
                            expression: "cityForm.business_id",
                          },
                        },
                        _vm._l(_vm.logisticsList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市仓类型", prop: "logistics_type" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.isEdit,
                            clearable: "",
                            placeholder: "选择城市仓类型",
                          },
                          model: {
                            value: _vm.cityForm.logistics_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.cityForm, "logistics_type", $$v)
                            },
                            expression: "cityForm.logistics_type",
                          },
                        },
                        _vm._l(_vm.logisticsTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.isEdit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "自提点数量", prop: "delivery_number" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: true,
                              placeholder: "请输入自提点数量",
                              clearable: "",
                            },
                            model: {
                              value: _vm.cityForm.delivery_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.cityForm, "delivery_number", $$v)
                              },
                              expression: "cityForm.delivery_number",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "截单时间", prop: "dateTime" } },
                [
                  _c("div", { staticClass: "d-flex a-center" }, [
                    _c(
                      "div",
                      [
                        _c("el-time-picker", {
                          attrs: {
                            format: "HH:mm",
                            "value-format": "HH:mm",
                            placeholder: "开始时间",
                          },
                          model: {
                            value: _vm.cityForm.startTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.cityForm, "startTime", $$v)
                            },
                            expression: "cityForm.startTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", [_vm._v("—")]),
                    _c(
                      "div",
                      [
                        _c("el-time-picker", {
                          attrs: {
                            format: "HH:mm",
                            "value-format": "HH:mm",
                            placeholder: "结束时间",
                          },
                          model: {
                            value: _vm.cityForm.endTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.cityForm, "endTime", $$v)
                            },
                            expression: "cityForm.endTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm.upgradePosition === _vm.UPGRADE_POSITION_ENUM.upgrade.value
                ? [
                    _c("el-form-item", { attrs: { label: "账号昵称" } }, [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入账号昵称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.cityForm.nickname,
                              callback: function ($$v) {
                                _vm.$set(_vm.cityForm, "nickname", $$v)
                              },
                              expression: "cityForm.nickname",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "登录账号", prop: "account_number" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入登录账号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.cityForm.account_number,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cityForm,
                                      "account_number",
                                      $$v
                                    )
                                  },
                                  expression: "cityForm.account_number",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "suffix",
                                      type: "text",
                                      disabled: !_vm.businessUserId,
                                    },
                                    on: { click: _vm.onHandleResetPassword },
                                    slot: "suffix",
                                  },
                                  [_vm._v("重置密码")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "提现银行卡", prop: "card_code" } },
                      [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入提现银行卡",
                                clearable: "",
                              },
                              model: {
                                value: _vm.cityForm.card_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cityForm, "card_code", $$v)
                                },
                                expression: "cityForm.card_code",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属银行", prop: "bank_code" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "选择所属银行",
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.depositList = []
                                    _vm.cityForm.bank_deposit = ""
                                  },
                                },
                                model: {
                                  value: _vm.cityForm.bank_code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cityForm, "bank_code", $$v)
                                  },
                                  expression: "cityForm.bank_code",
                                },
                              },
                              _vm._l(_vm.bankList, function (item) {
                                return _c("el-option", {
                                  key: item.bank_code,
                                  attrs: {
                                    label: item.bank_name,
                                    value: item.bank_code,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.cityForm.bank_name = item.bank_name
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "开户行", prop: "bank_deposit" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "remote-method": _vm.ajaxGetBranchList,
                                  loading: _vm.depositLoading,
                                  clearable: "",
                                  filterable: "",
                                  remote: "",
                                  placeholder: "选择开户行",
                                },
                                model: {
                                  value: _vm.cityForm.bank_deposit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cityForm, "bank_deposit", $$v)
                                  },
                                  expression: "cityForm.bank_deposit",
                                },
                              },
                              _vm._l(_vm.depositList, function (item) {
                                return _c("el-option", {
                                  key: item.code,
                                  attrs: {
                                    label: item.branch_name,
                                    value: item.branch_name,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "持卡人", prop: "username" } },
                      [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入持卡人",
                                clearable: "",
                              },
                              model: {
                                value: _vm.cityForm.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cityForm, "username", $$v)
                                },
                                expression: "cityForm.username",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "预留电话", prop: "bank_mobile" } },
                      [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入预留电话",
                                maxlength: "11",
                                clearable: "",
                              },
                              model: {
                                value: _vm.cityForm.bank_mobile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cityForm, "bank_mobile", $$v)
                                },
                                expression: "cityForm.bank_mobile",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "客服电话", prop: "service_mobile" } },
                      [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: 11,
                                placeholder: "请输入客服电话",
                                clearable: "",
                              },
                              model: {
                                value: _vm.cityForm.service_mobile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cityForm, "service_mobile", $$v)
                                },
                                expression: "cityForm.service_mobile",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "身份证国徽页图片",
                          prop: "riseIdCardA",
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.uploadLoading,
                                expression: "uploadLoading",
                              },
                            ],
                            class: {
                              hide: _vm.riseIdCardA.length || !_vm.riseIdCardA,
                            },
                            attrs: {
                              action: "#",
                              "on-preview": _vm.onHandlePictureCardPreview,
                              "file-list": _vm.riseIdCardA,
                              "list-type": "picture-card",
                              "http-request": (data) =>
                                _vm.onHandleUploadFile(data, "riseIdCardA"),
                              "before-remove": () =>
                                _vm.onHandleDeleteFile("riseIdCardA"),
                              limit: 1,
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              attrs: { slot: "trigger" },
                              slot: "trigger",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "身份证人像页图片",
                          prop: "riseIdCardB",
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.uploadLoading,
                                expression: "uploadLoading",
                              },
                            ],
                            class: {
                              hide: _vm.riseIdCardB.length || !_vm.riseIdCardB,
                            },
                            attrs: {
                              action: "#",
                              "on-preview": _vm.onHandlePictureCardPreview,
                              "file-list": _vm.riseIdCardB,
                              "list-type": "picture-card",
                              "http-request": (data) =>
                                _vm.onHandleUploadFile(data, "riseIdCardB"),
                              "before-remove": () =>
                                _vm.onHandleDeleteFile("riseIdCardB"),
                              limit: 1,
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              attrs: { slot: "trigger" },
                              slot: "trigger",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "运距", prop: "shipment_distance" } },
                      [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticClass: "distance-style",
                              attrs: {
                                placeholder: "请输入运距",
                                clearable: "",
                              },
                              on: {
                                blur: () =>
                                  (_vm.cityForm.shipment_distance =
                                    _vm.utilsTool.filterUnNumber(
                                      _vm.cityForm.shipment_distance
                                    )),
                              },
                              model: {
                                value: _vm.cityForm.shipment_distance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cityForm,
                                    "shipment_distance",
                                    $$v
                                  )
                                },
                                expression: "cityForm.shipment_distance",
                              },
                            }),
                            _vm._v(" 公里 "),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }